<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <div class="wrap__block wrap__block-container">
        <div class="wrap__block__header wrap__header">
          <div class="">
            <span>{{ $t('icp_target_integration.title_page_integrate') }}</span>

            <div class="header-desc">
              <span class="list-confirm">
                {{ $t('icp_target_integration.description_integrate_page_1') }}<br />
                {{ $t('icp_target_integration.description_integrate_page_2') }}
              </span>

              <common-button
                :isDisable="dataSelected.length <= 1"
                @action="checkItemSelect"
                class="submit-register-btn common-btn"
                :label="$t('icp_target_integration.button_integrate')"
                v-ripple="false"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="category-table main-table custom-table new-register view-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          @updateDatatitle="updateDataTitle"
          :data-source="listData"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :isShowDuration="false"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="true"
          :isResizeCustome="false"
          :isDisableBySetting="dataSelected.length <= 1"
          @changeFullScreen="onChangeFullScreen"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
          @flexGridInitialDone="onFlexGridInitialDone"
          @onHandleActionTable="checkItemSelect"
          class="table-reduction-target-list"
          :isEmptySource="isEmptySource"
          :labelEmpty="messageLabelEmpty"
        />
      </div>
    </div>
    <question-popup
      :dialog="dialogConfirm"
      :confirm="true"
      :message="$t('icp_target_integration.popup_question_integarte')"
      :confirmText="$t('icp_target_integration.button_create_integrate')"
      @close="closeConfirm()"
      @submit="submitAction()"
      :isConfirmSettingTaget="true"
      classes="register-question"
    >
      <template>
        <div class="mt-5 list-confirm">
          <ul>
            <li v-for="item in dataSelected" :key="item.id">{{ item.title }}</li>
          </ul>
        </div>
      </template>
    </question-popup>

    <ErrorPopup
      :dialog="errorDialog"
      :message="$t('icp_target_integration.popup_error_integrate')"
      @submit="errorDialog = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { CollectionView } from '@mescius/wijmo';
import _ from 'lodash';
import DataTable from '@/components/category/data-table';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import * as wjGrid from '@mescius/wijmo.grid';
import * as wjcCore from '@mescius/wijmo';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { REGISTER_PULLDOWN, LIST_PULLDOWN } from '@/constants/registerData';
import { $_helper_isNumberType, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { scopeCategoriesData, newPcafMethod, oldmethod } from '@/constants/export-report';
import { getListReductionTargetApi, checkIntegrateData } from '@/api/reduction-target';
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
import CheckBoxHomeStandard from '@/components/dashboard/home-standard/checkbox-standard';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import QuestionPopup from '@/components/dialogs/question-popup';
import { RestrictedMergeManager } from '@/constants/reduction';
import { getWidthButtonByText } from '@/utils/multiLanguage';
import { getDataTypeApi } from '@/api/pcaf'
import { setDataTypeForPcafMethod } from '@/utils/pcaf'
import { handleGetScopeCategories } from '@/utils/getScopeCategories';

export default {
  name: 'SettingTarget',
  data() {
    return {
      items: [
        {
          text: this.$t('list_reduction.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('reduction.title_title_page'),
          disabled: false,
          href: ROUTES.PRODUCT_REDUCTION,
        },
        {
          text: this.$t('icp_target_integration.title_page_integrate'),
          disabled: false,
          href: ROUTES.SETTING_TARGET,
        },
      ],
      duration: '',
      flex: null,
      lisReductionTargetData: [],
      listData: null,
      commentPopup: false,
      historyPopup: false,
      errorDialog: false,
      gridColumns: [],
      showTooltip: false,
      dialogConfirm: false,
      selectedFilterColumn: null,
      organizationList: [
        { id: 1, value: this.$t('reduction.label_single_company') },
        { id: 2, value: this.$t('reduction.label_subsidiaries_company') },
        { id: 3, value: this.$t('reduction.label_associated_company') },
        { id: 4, value: this.$t('reduction.label_associated_company_other') },
      ],
      dataTitle: null,
      isEmptySource: true,
      isFixedButton: false,
      checkBoxCellElement: {},
      dataSelected: [],
      idNewtaget: '',
      allDataType: {}
    };
  },
  components: {
    DataTable,
    CommonButton,
    NotificationPopup,
    CheckBoxHomeStandard,
    ErrorPopup,
    QuestionPopup,
  },
  async mounted() {
    if (this.isSettingPcaf) {
      await this.handleGetDataType()
    }
    await this.updateBreadCrumb(this.items);
    this.actionUpdateIsLoadingTable(true);
    await this.getDataTarget();
    this.actionUpdateIsLoadingTable(false);
  },
  computed: {
    ...mapState('commonApp', ['loadMore', 'isFilter', 'isExpand']),
    ...mapState('registerData', ['isFullScreen', 'isChangedDurationSuccess']),
    ...mapState('userData', ['currentUser', 'isSettingPcaf', 'planType']),
    isAdmin() {
      return this.currentUser?.user?.role_id === 3;
    },
    registerPulldown() {
      return Object.values(REGISTER_PULLDOWN);
    },
    listPullDown() {
      return LIST_PULLDOWN;
    },
    listPullDownSp() {
      return LIST_PULLDOWN.slice(0, 2);
    },
    labelBtnSignUp() {
      return this.$t('list_reduction.button_sign_up');
    },
    messageDurationSuccess() {
      return this.$t('list_reduction.description_message_duration_success');
    },
    messageLabelEmpty() {
      return this.$t('list_reduction.description_reduction_empty');
    },
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },

    updateDataTitle(value) {
      this.lisReductionTargetData = this.lisReductionTargetData.filter((data) =>
        data.title.toLowerCase().includes(value.toLowerCase()),
      );
      this.flex.refresh();
    },
    navigateToCreateInforReduction() {
      return this.$router.push({ path: ROUTES.CREATE_REDUCTION_TARGET });
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode,
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      flexgrid.hostElement.addEventListener('click', (el) => {
        if (el) {
          if(wjcCore.hasClass(el.target.childNodes[0], 'checkbox-box-custom')) {
            const idItem = this.lisReductionTargetData[this.flex.selection.row].id;
            const indexItemFirst = this.lisReductionTargetData.findIndex((item) => item.id_selected == `${idItem}-0`);
            this.flex.rows[indexItemFirst].dataItem.is_selected = this.lisReductionTargetData[indexItemFirst].is_selected === 0 ? 1 : 0;
            this.updateGrid(this.flex.rows[indexItemFirst].dataItem);
            this.handleDataSelect(this.flex.rows[indexItemFirst].dataItem);
            this.$nextTick(() => {
              this.flex.refresh();
            });
          }
        }
      });
      flexgrid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      flexgrid.formatItem.addHandler((s, e) => {
        if (e.panel == s.cells) {
          if (e.panel.columns[e.col].binding === 'id') {
            this.checkBoxCellElement = s.columns[e.col];
          }
          this.formatCell(e.cell, s.rows[e.row].dataItem, s.columns[e.col]);
        }
      });
      this.flex.onSelectionChanged(null);
      flexgrid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        if (column.binding !== 'data_id') {
          e.cancel = true;
        }

        if (column.binding !== 'id') {
          return;
        }
      });
      flexgrid.mergeManager = new RestrictedMergeManager()
    },

    updateGrid(item) {
      this.flex.columns.forEach((col) => {
        let cell = this.checkBoxCellElement;
        if (cell) {
          this.formatCell(cell, item, col);
        }
      });
    },

    formatCell(cell, item, col) {
      if (col.binding === 'id') {
        let html = item.is_selected
          ? `<div class="checkbox-box-custom checked" inert>
      <svg class="svg-checkbox" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999756 3.8L4.59976 7.4L10.9998 1" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
    </div> `
          : `<div class="checkbox-box-custom" inert>
      <svg class="svg-checkbox" width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.999756 3.8L4.59976 7.4L10.9998 1" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
    </div>  `;

        cell.innerHTML = html;
      }
    },

    handleDataSelect(item) {
      const indexItem = this.dataSelected.findIndex((rc) => rc.id === item.id);
      if (!item.is_selected && indexItem >= 0) {
        this.dataSelected.splice(indexItem, 1);
      } else {
        this.dataSelected.push(item);
      }
    },

    checkItemSelect() {
      let params = '';
      this.dataSelected.forEach((item, index) => {
        params = params + `id[]=${item.id}` + `${index === this.dataSelected.length - 1 ? '' : '&'}`;
      });
      checkIntegrateData(params)
        .then((res) => {
          if (res.latest_record) {
            this.$store.dispatch('reductionTarget/setTargetSelect', res.latest_record);
            this.idNewtaget = res.latest_record.id;
            this.dialogConfirm = true;
          } else {
            this.errorDialog = true;
          }
        })
        .catch((_) => {
          this.errorDialog = true;
        });
    },

    closeConfirm() {
      this.dialogConfirm = false;
      this.$store.dispatch('reductionTarget/setTargetSelect', null);
    },

    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = false;
      }
    },
    getInfoBranch(branch, fieldName) {
      if (fieldName == 'company_name') {
        const companyName = branch.map((item) => (item[fieldName] + '').trim());
        const result = Array.from(new Set(companyName))?.join(', ');
        return result;
      }
      const information = branch.map((item) => item[fieldName])?.join(', ');
      return information;
    },
    async getDataTarget() {
      getListReductionTargetApi({ is_integrate: 1 }).then((res) => {
        res.data.forEach((item) => {
          const indexScope3Category15 = scopeCategoriesData.findIndex((i) => i.categories === 15);
          let dataScope = JSON.parse(JSON.stringify([...scopeCategoriesData]))
          let pcafMethod = newPcafMethod
          if (this.planType?.is_pcaf && !this.allDataType.length) {
            pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
          }
          dataScope[indexScope3Category15].methods = this.planType?.is_pcaf ? pcafMethod : oldmethod;
          const listScopeCategories = handleGetScopeCategories(item.pattern_ids, dataScope, this.allDataType).map(
            (item) => item.text,
          );
          listScopeCategories.forEach((scopeCategory, index) => {
            this.lisReductionTargetData.push({
              id: item.id,
              data_id: item.id,
              id_selected: `${item.id}-${index}`,
              title: item.title || null,
              last_modified_by: item.updated_by_user.name || null,
              last_modified: formatDateTimeDataTable(item.updated_at),
              start_date:  this.$t('list_reduction.table_year_fiscal_standard_target', { year: item.start_year }),
              end_date: this.$t('list_reduction.table_year_fiscal_standard_target', { year: item.end_year }),
              group: this.organizationList
                    .filter((record) =>
                      this.getInfoBranch(item.branches.organizational_division, 'organizational_division').includes(
                        record.id,
                      ),
                    )
                    .map((ele) => ele.value),
              organization: this.getInfoBranch(item.branches.company_name, 'company_name'),
              range_type: scopeCategory,
              is_selected: 0,
            });
          });
        });
        this.defineTable();
      });
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.listData = new CollectionView([...this.lisReductionTargetData], {
        sortComparer: (a, b) => {
          if (this.selectedFilterColumn !== 'category') return null;
          const valueA = a?.split('カテゴリ')[1]?.trim();
          const valueB = b?.split('カテゴリ')[1]?.trim();
          if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
            const numA = Number(formatValue(valueA));
            const numB = Number(formatValue(valueB));
            return numA - numB;
          }
          return null;
        },
      });
      this.flex.columnGroups = this.getGridColumns();
      this.listData.currentItem = null;
      this.isEmptySource = this.listData?.items?.length <= 0;
      setMinMaxSizeColumns(this.flex, this.lisReductionTargetData);
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },

    submitAction() {
      this.$router.push({ name: 'SettingReductionIntegrate', query: { id: this.idNewtaget } });
    },
    getGridColumns() {
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'id',
          minWidth: 46,
          maxWidth: 60,
          allowSorting: false,
          isRequired: false,
          visible: true,
          cssClassAll: 'btn-db table-id hide-filter center-item',
          allowMerging: true,
        },
        {
          header: this.$t('list_reduction.table_title'),
          binding: 'title',
          minWidth    : 100,
          maxWidth: 860,
          isRequired: false,
          cssClassAll: 'btn-db history-btn',
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('list_reduction.table_start_date'),
          binding: 'start_date',
          minWidth    : getWidthByTextContent(this.$t("list_reduction.table_start_date")),
          maxWidth : 250,
          allowSorting: false,
          cssClassAll: 'btn-db',
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('list_reduction.table_end_date'),
          binding: 'end_date',
          minWidth    : getWidthByTextContent(this.$t("list_reduction.table_end_date")),
          maxWidth: 250,
          allowSorting: false,
          isRequired: false,
          cssClassAll: 'btn-db',
          multiLine: false,
          wordWrap: true,
        },
        {
          header: this.$t('list_reduction.table_organizational_intelligence'),
          align: 'center',
          collapseTo: 'group',
          columns: [
            {
              header: this.$t('list_reduction.table_group'),
              binding: 'group',
              minWidth    : getWidthByTextContent(this.$t("list_reduction.table_organizational_intelligence")),
              maxWidth: 860,
              allowSorting: false,
              isRequired: false,
              cssClassAll: 'btn-db',
              wordWrap: true,
              multiLine: true,
            },
            {
              header: this.$t('list_reduction.table_organization'),
              binding: 'organization',
              minWidth    : 104,
              maxWidth: 860,
              allowSorting: false,
              isRequired: false,
              cssClassAll: 'btn-db',
              wordWrap: true,
              multiLine: true,
            },
          ],
        },
        {
          header: ' ',
          align: 'center',
          collapseTo: 'range_type',
          columns: [
            {
              header: this.$t('list_reduction.table_range_type'),
              binding: 'range_type',
              minWidth: 255,
              width: '*',
              allowSorting: false,
              isRequired: false,
              cssClassAll: 'btn-db',
              wordWrap: true,
              multiLine: true,
            },
          ],
        },
        {
          header: '',
          header: ' ',
          align: 'center',
          collapseTo: 'last_modified_by',
          columns: [
            {
              header: this.$t('list_reduction.table_last_modified_by'),
              binding: 'last_modified_by',
              minWidth : getWidthByTextContent(this.$t("list_reduction.table_last_modified_by")),
              maxWidth: 247,
              allowSorting: false,
              isRequired: false,
              cssClassAll: 'btn-db',
              multiLine: true,
              wordWrap: true,
            },
          ],
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },

    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        // this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    onFlexGridInitialDone(flexGrid) {
      // flexGrid.mergeManager = new RestrictedMergeManager();
    },
  },
};
</script>
<style lang="scss">
.checkbox-box-custom {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s;
  background: $monoWhite;
}

.checked {
  border-color: $blueMid;
  background-color: $blueMid;
}

.checkmark {
  width: 16px;
  height: 16px;
}

.checked .checkmark {
  fill: #007bff;
  /* Change checkmark color to blue */
}

.center-item {
  display: flex;
  justify-content: center;
}
</style>
<style scoped lang="scss">
.list-confirm {
  color: $monoBlack !important;
  // // font-family: 'Source Han Sans JP';
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important; /* 186.667% */
  letter-spacing: 0.75px !important;
}
.template-detail-header {
  .wj-btn {
    display: none;
  }
}

.wj-flexgrid .wj-cell-maker {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $monoBlack;
  // font-family: 'Source Han Sans JP';
}

.wj-cell {
  .wj-cell-maker {
    background: none;
    justify-content: start;
  }

  .wj-cell-maker:hover {
    background: none;
    color: $monoBlack;
  }
}

.duration-wrapper {
  .duration-text {
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    margin-bottom: 8px;
  }

  .calculation-page {
    display: flex;
    align-items: center;
    background-color: $bgLight;
    padding: 15px;
    border-radius: 4px;
    height: 56px;

    div {
      margin-right: 20px;

      .duration-content {
        color: $monoBlack;
        font-weight: 700;
        font-size: 11px;
        line-height: 26px;
        letter-spacing: 0.03em;
        padding: 5px 0px 0px 0px;
      }

      .duration-text {
        color: $goldMid;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.03em;
      }
    }
  }
}

.template-detail-header {
  .wj-cell-maker {
    margin-top: 2px;
    width: 26px;
    height: 20px;
    font-style: normal;
    // font-family: 'Source Han Sans JP';
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    color: $goldMid;
    text-align: center;
    background: none;
    border: 1px solid $goldLight;
  }

  .wj-cell-maker:hover {
    color: $monoWhite;
    background: #0072a3;
  }
}

.hide-filter {
  .wj-btn {
    display: none;
  }
}

.custom-table {
  padding-left: 20px !important;
}

.wrap {
  width: 100%;
  height: 100%;

  .wrap__block-container {
    padding: 0 0 44px;
  }

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 0;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: none;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    .duration-wrapper {
      width: 100%;

      .duration {
        .calculation-page {
          justify-content: space-between;
        }
      }
    }

    .user-group-area .user-group-btn {
      font-size: 14px;
      color: #404d4d;
      font-weight: 500;
      width: 103px;
      height: 40px;
    }
  }

  &__header {
    margin-bottom: 48px;
    margin-top: 40px;

    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;

      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    padding: 0 0 40px;

    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;
      padding-bottom: 0px;

      &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      span {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;

        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }

      .helper-icon {
        img {
          margin-bottom: -2px;
        }
      }
    }

    .wrap__status,
    .wrap__header {
      margin-bottom: 0px;
    }

    .button-icon {
      margin-right: 8px;
    }

    .btn-register {
      padding: 2px 10px;
    }

    .wrap__status__item {
      span {
        margin-left: 10px;
      }
    }
  }
}

.return {
  &__button-responsive {
    &.fixed-button-bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      top: unset;

      .item-responsive {
        width: 90% !important;
      }
    }

    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    padding: 16px 20px;
    position: relative;
    bottom: -80px;
    margin-left: -20px;

    .item-responsive {
      width: 100%;
      margin: 0 auto;

      .common-btn {
        width: 100% !important;

        .common-btn-text {
          margin-left: 10px;
        }

        .addregistericon_active {
          display: none;
        }

        .addregistericon_disabled {
          display: none;
        }
      }
    }
  }
}

.header-desc {
  margin-top: 48px;

  .submit-register-btn {
    margin-top: 16px;
  }

  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background: $monoDusty !important;
  }
}

@include desktop {
  .header-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    margin-top: 48px;

    .submit-register-btn {
      margin-top: 0px;
    }
  }
  .wrap {
    &__header {
      margin-top: unset;
      margin-left: 40px;
      margin-right: 40px;
    }

    &__status {
      padding-right: 40px;

      &__item {
        display: block;
      }
    }

    &__block {
      flex-direction: row;

      &__header {
        padding-bottom: 0;
      }
    }

    .wrap__block__header__content {
      display: flex;

      span {
        margin: 8px 10px 0px 0px;
      }

      // .helper-icon {
      //   margin: 16px 10px 0px 0px;
      // }
    }

    .wrap__status__item {
      .addregistericon_active {
        display: none;
      }

      .addregistericon_disabled {
        display: none;
      }

      .register-button {
        display: flex;
        align-items: center;
        width: 103px;

        &_warp {
          display: flex;
          // margin-right: 8px;
        }

        &_pulldown {
          transition: all 0.3s ease-out;

          .btn-icon {
            display: block;
          }

          .btn-icon_hover {
            display: none;
          }

          &.open-pulldown {
            transform: rotate(180deg);
          }
        }

        &:hover {
          background-color: $monoWhite !important;

          .v-btn__content {
            .register-button_warp {
              span {
                color: $monoBlack;
              }
            }

            .register-button_pulldown {
              .btn-icon {
                display: none;
              }

              .btn-icon_hover {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.template-register-button.disabled {
  pointer-events: none;
  cursor: default;
  background: $monoLight !important;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24) !important;

  .v-btn__content {
    span {
      color: $monoMid !important;
    }

    .addregistericon {
      display: none !important;
    }

    .addregistericon_active {
      display: none;
    }

    .addregistericon_disabled {
      display: block;
    }
  }
}

@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }

  .return {
    &__header {
      margin-top: unset;
    }
  }

  .return__button-responsive {
    display: none;
  }

  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .wrap__status .user-group-area .user-group-btn {
    margin-bottom: 0px !important;
  }
}

//custome style
@media screen and (min-width: 1024px) and (max-width: 1150px) {
  .wrap {
    &__block {
      flex-direction: column;

      &__header {
        padding-bottom: 58px;
      }
    }

    //&__status {
    //  padding-right: 0;
    //}
  }
}
</style>
<style lang="scss">
.table-reduction-target-list {
  .wj-elem-collapse {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 9px;
    top: 9px;
    background: rgba(121, 134, 134, 0.12);
    border-radius: 6px;
    margin-right: 4px;
  }

  .wj-elem-collapse {
    &.wj-glyph-minus {
      color: $monoDark;
      border-top: 1px solid;
    }
  }

  .wj-cell.merge-rows {
    border-bottom: unset !important;
  }
}
</style>


